import { widgetize, WidgetPropsMapping } from '@sg-widgets/react-core';
import { DsDropZone } from '@sgdocs/design-system';
import React from 'react';
import { EWidgetsSgmDocs } from '../common/widget/enum';

interface IDsDropZoneProps {
  onSelectedFiles: (files: File[]) => void;
  type?: string;
  addLabel?: string;
  dragLabel?: string;
  selectLabel?: string;
  fileLabel?: string;
  browseLabel?: string;
}

const SGMDocsDropZone: React.FC<IDsDropZoneProps> = (props: IDsDropZoneProps): JSX.Element => {
  return (
    <DsDropZone
      type={props.type}
      onSelectedFiles={props.onSelectedFiles}
      addLabel={props.addLabel}
      dragLabel={props.dragLabel}
      selectLabel={props.selectLabel}
      fileLabel={props.fileLabel}
      browseLabel={props.browseLabel}
    />
  );
};

widgetize(EWidgetsSgmDocs.DROP_ZONE, SGMDocsDropZone, {
  type: WidgetPropsMapping.asString({
    description: 'The type of the component, could be large, medium, compact or small',
  }),

  onSelectedFiles: WidgetPropsMapping.asEventEmitter('selected-files', {
    description:
      "Event sent when the user has selected files, it contains an array of <a class='btn btn-link text-info px-0 mb-0' href='https://developer.mozilla.org/en-US/docs/Web/API/File'>File</a> object",
  }),

  addLabel: WidgetPropsMapping.asString({
    description: 'Translated label "Add attachment"',
  }),

  dragLabel: WidgetPropsMapping.asString({
    description: 'Translated label "Drag or"',
  }),

  selectLabel: WidgetPropsMapping.asString({
    description: 'Translated label "select"',
  }),

  fileLabel: WidgetPropsMapping.asString({
    description: 'Translated label "your file to this area to upload"',
  }),

  browseLabel: WidgetPropsMapping.asString({
    description: 'Translated label "Browse file"',
  })
},
  { neverUseShadowDOM: true }
);
